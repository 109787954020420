footer {
	position: relative;
	width: 100%;
	padding: 30px 0 30px;
	z-index: 1;
	// background-color: #2c2c2c;
	box-shadow: 0 0 40px 5px rgba($color: #000000, $alpha: 0.1);
	@include respond-to('large') {
		padding: 20px 0 10px;
	}
}

.ftr-wrap {
	position: relative;
	z-index: 1;
	&__right {
		width: 22%;
		float: $right;
		@include respond-to('1100') {
			float: none;
			width: 100%;
			max-width: 250px;
			margin: 0 auto 15px;
			text-align: center;
		}
		@include respond-to('medium') {
			margin-bottom: 0;
		}
	}
	&__left {
		width: 78%;
		float: $left;
		padding-top: 40px;
		@include respond-to('1100') {
			float: none;
			width: 100%;
		}
		@include respond-to('medium') {
			padding-top: 20px;
		}
		@include respond-to('extra-small') {
			padding-top: 10px;
		}
	}
}

.link-col {
	float: $right;
	width: 25%;
	@include respond-to('small') {
		width: 50%;
	}
}

.hr-footer-bottom {
	border: 0;
	border-top: 1px solid #555;
	margin-top: 40px;
	margin-bottom: 30px;
	@include respond-to('large') {
		margin-top: 20px;
		margin-bottom: 10px;
	}
	@include respond-to('extra-small') {
		margin-top: 10px;
	}
}

.ftr-logo {
	// text-align: center;
	@include respond-to('medium') {
		text-align: center;
	}
	& img {
		max-width: 216px;
		@include respond-to('medium') {
			max-width: 150px;
		}
	}
	& a {
		margin-#{$right}: 55px;
		@include respond-to('1100') {
			margin-#{$right}: 0;
		}
	}
}

.ftr-contact {
	padding-top: 20px;
	padding-#{$left}: 15px;
	@include respond-to('1100') {
		max-width: 250px;
		margin: 10px auto 10px;
		padding-top: 15px;
		padding-#{$left}: 0px;
	}
	@include respond-to('medium') {
		padding-top: 0;
	}
	&__item {
		margin-bottom: 5px;
		color: #fff;
		@include respond-to('medium') {
			margin-bottom: 5px;
		}
		& i {
			display: inline-block;
			vertical-align: middle;
			width: 15%;
			margin-#{$left}: -4px;
			color: $accent;
			font-size: 20px;
		}
		& span {
			display: inline-block;
			vertical-align: middle;
			padding-#{$right}: 4px;
			width: 85%;
			font-size: 16px;
			font-weight: 400;
			@include respond-to('medium') {
				// font-size: 15px;
				// font-weight: 300;
			}
		}
	}
}



.credit-info-txt {
	display: inline-block;
	margin-#{$right}: 25px;
	color: $accent;
	float: $right;
	font-size: 20px;
	@include respond-to('medium') {
		display: block;
		text-align: center;
		margin-bottom: 10px;
	}
	@include respond-to('small') {
		margin-right: 0;
	}
	& > p {
		font-size: 18px;
		color: $accent;
		font-weight: 300;
		margin: 0;
		@include respond-to('extra-small') {
			font-size: 14px;
		}
	}
}

.wrap-social {
	position: relative;
	display: inline-block;
	margin-#{$right}: 25px;
	@include respond-to('large') {
		margin-#{$right}: 20px;
	}
	@include respond-to('medium') {
		display: block;
		text-align: center;
		margin: 0;
		margin-bottom: 10px;
	}
	& > a {
		& > i {
			margin-#{$left}: 15px;
			font-size: 19px;
		}
		& > .fa-linkedin-in {
			@include link_on-hover(#fff, $clr-linkedin);
		}
		& > .fa-youtube {
			@include link_on-hover(#fff, $clr-youtube);
		}
		& > .fa-facebook-f {
			@include link_on-hover(#fff, $clr-facebook);
		}
	}
}

.site-footer__credit-holder {
	float: $left;
	// margin-top: 20px;
	@include respond-to('medium') {
		float: none;
		// margin-top: 10px;
	}
}