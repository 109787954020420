.wrapper-all-content {
	background-position: top center;
	position: relative;
	width: 100%;
	&_pages {
		// top: -150px;
		// margin-bottom: -150px;
		// padding-top: 100px;
		// background-size: cover;
		@include respond-to('small') {
			// background-size: unset;
			padding-top: 1px;
		}
		&::after {
			content: '';
			position: absolute;
			left: 0;
			right: 0;
			top: -157px;
			width: 100%;
			height: 157px;
			background-image: url(../images/page-top.png);
			background-position: top center;
			background-repeat: no-repeat;
			background-size: cover;
			@include respond-to('small') {
				display: none;
			}
		}
		& .section-limit_cont {
			position: relative;
			z-index: 1;
			top: -50px;
			margin-bottom: -50px;
			@include respond-to('small') {
				top: 0px;
				margin-bottom: 0px;
			}
		}
	}
}

.title {
	margin: 0;
	font-size: 40px;
	font-weight: 900;
	line-height: 0.9;
	color: $accent;
	@include respond-to('medium') {
		font-size: 35px;
	}
	@include respond-to('small') {
		font-size: 30px;
	}
	&_with-label {
		position: relative;
		@include respond-to('small') {
			text-align: center;
		}
		&::after {
			display: block;
			margin-top: 5px;
			// content: url(../images/title-logo.png);
		}
	}
	&_tac {
		text-align: center;
	}
	&_mb {
		margin-bottom: 55px;
		@include respond-to('small') {
			margin-bottom: 35px;
		}
		@include respond-to('extra-small') {
			margin-bottom: 25px;
		}
	}
	&_prod-i {
		@include respond-to('large') {
			text-align: center;
		}
	}
}

.hp-about {
	position: relative;
	top: -120px;
	margin-bottom: -120px;
	padding: 172px 0 100px;
	background-position: top center;
	background-repeat: no-repeat;
	@include respond-to('huge') {
		padding: 120px 0 90px;
		background-position: top #{$right} -200px;
	}
	@include respond-to('large') {
		background-position: top #{$left} -100px;
		padding-top: 140px;
	}
	@include respond-to('medium') {
		background-position: top #{$left} -140px;
		padding-bottom: 50px;
	}
	@include respond-to('small') {
		background-image: none !important;
		padding: 10px 0 30px;
		top: 0;
		margin-bottom: 0;
	}
	@include respond-to('extra-small') {
		padding: 20px 0 50px;
	}
	&__wrap {
		// max-width: 830px;
		text-align: center;
		width: 100%;
		@include respond-to('large') {
			margin: 0 auto;
		}
	}
	&__title {
		display: inline-block;
		@include respond-to('small') {
			display: block;
			margin-top: 25px;
		}
		@include respond-to('extra-small') {
			margin-top: 15px;
		}
		&_pages {
			display: block;
			margin-bottom: 50px;
			text-align: center;
			@include respond-to('large') {
				margin-bottom: 40px;
			}
			@include respond-to('medium') {
				margin-bottom: 35px;
				margin-top: 0px;
			}
			@include respond-to('extra-small') {
				margin-bottom: 25px;
			}
		}
	}
	&__icons {
		position: absolute;
		#{$right}: 0;
		top: -32px;
		@include respond-to('1550') {
			top: 0;
			#{$right}: -50px;
		}
		@include respond-to('huge') {
			#{$right}: -200px;
		}
		@include respond-to('large') {
			display: none;
		}
	}
	&__desc {
		margin-top: 50px;
		z-index: 2;
		position: relative;
		@include respond-to('small') {
			margin-top: 30px;
		}
		@include respond-to('extra-small') {
			margin-top: 20px;
		}
		& p {
			color: $accent;
			font-size: 18px;
			font-weight: 400;
			@include respond-to('extra-small') {
				font-size: 16px;
				text-align: justify;
			}
		}
	}
	&__prods {
		padding-top: 50px;
		@include respond-to('small') {
			padding-top: 20px;
		}
	}
}

.hp-products {
	position: relative;
	background-color: #f4f4f4;
	background-repeat: no-repeat;
	background-size: contain;
	padding-bottom: 120px;
	padding-top: 85px;
	// min-height: 550px;
	@include respond-to('large') {
		padding-bottom: 50px;
		padding-top: 40px;
	}
	@include respond-to('small') {
		padding-bottom: 25px;
		padding-top: 20px;
	}
	&__title {}
	&__boxes {
	
	}
}

.products {
	font-size: 0;
	margin: 75px -30px 0;
	text-align: center;
	@include respond-to('larger') {
		margin: 45px -20px 0;
	}
	@include respond-to('1100') {
		margin: 45px -10px 0;
	}
	&_mt45 {
		margin-top: 45px;
		@include respond-to('small') {
			margin-top: 25px;
		}
	}
	&_mt25 {
		margin-top: 25px;
	}
	&__item {
		display: inline-block;
		width: 33.33%;
		vertical-align: top;
		font-size: inherit;
		padding: 30px;
		@include respond-to('larger') {
			padding: 20px;
		}
		@include respond-to('1100') {
			padding: 10px;
		}
		@include respond-to('medium') {
			width: 50%;
		}
		@include respond-to('small') {
			width: 100%;
		}
	}
}


.hp-boxes {
	font-size: 0;
	direction:  if-rtl('rtl', 'ltr');
	&__item {
		margin-top: 35px;
		display: inline-block;
		width: 25%;
		font-size: initial;
		@include respond-to('medium') {
			width: 50%;
		}
		@include respond-to('extra-small') {
			width: 100%;
		}
	}
}

.h-c {
	position: relative;
	background-repeat: no-repeat;
	background-position: 50%;
	background-size: contain;
	height: 270px;
}

.h-c-text {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	max-width: 230px;
	width: 100%;
	color: #003e5b;
	font-size: 20px;
	font-weight: 900;
	text-align: center;
	padding: 0 15px;
	@include respond-to('small') {
		font-size: 18px;
	}
}

.hp-video {
	padding-bottom: 120px;
	padding-top: 85px;
	@include respond-to('large') {
		padding-bottom: 50px;
		padding-top: 40px;
	}
	@include respond-to('small') {
		padding-bottom: 25px;
		padding-top: 20px;
	}
}

.hp-videos {
	padding-top: 75px;
	@include respond-to('large') {
		padding-top: 40px;
	}
	@include respond-to('medium') {
		padding-top: 30px;
	}
	@include respond-to('small') {
		padding-top: 30px;
	}
	@include respond-to('extra-small') {
		padding-top: 25px;
	}
}


.wrapper-prod-gallery {
	// max-width: 790px;
	width: 100%;
	// border: 1px solid #d6d6d6;
	position: relative;
	@include respond-to('large') {
		margin: 0 auto;
	}
	@include respond-to('medium') {
		margin-bottom: 15px;
		max-width: 650px;
		width: 100%;
	}
	@include respond-to('small') {
		height: auto;
	}
	&_padding {
		// padding: 20px;
		@include respond-to('extra-small') {
			// padding: 15px;
		}
	}
}

.prod-gallery {
	position: relative;
	width: 100%;
	// height: 580px;
	@include respond-to('small') {
		height: auto;
	}
	&_padding {
		padding: 0px;
		overflow: hidden;
	}
	&__img-big {
		position: relative;
		width: 100%;
		max-height: auto;
		height: 650px;
		@include respond-to('1100') {
			height: 550px;
		}
		@include respond-to('medium') {
			height: 370px;
		}
		@include respond-to('small') {
			height: unset;
		}
		&:hover {
			// cursor: zoom-in;
		}
		img {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			margin: 0 auto;
			max-width: 100%;
			max-height: 100%;
			@include respond-to('small') {
				position: static;
				transform: none;
			}
		}
		&_youtube {
			&::before {
				content: "\f167";
				font-family: "Font Awesome 5 Brands";
				color: $clr-youtube;
				font-size: 60px;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				z-index: 2;
				cursor: pointer;
			}
		}
	}
	// & .slick-arrow {
	// 	height: 46px;
	// }
	// & .slick-prev {
	// 	right: 50px;
	// 	@include respond-to('extra-small') {
	// 		right: 15px;
	// 	}
	// 	&::before {
	// 		height: 46px;
	// 		content: if-rtl(url(../images/arr-right.png), url(../images/arr-left.png));
	// 	}
	// }
	// & .slick-next {
	// 	left: 50px;
	// 	@include respond-to('extra-small') {
	// 		left: 15px;
	// 	}
	// 	&::before {
	// 		height: 46px;
	// 		content: if-rtl(url(../images/arr-left.png), url(../images/arr-right.png));
	// 	}
	// }
}

.prod-gallery-navi {
	position: relative;
	width: 100%;
	margin: 0 auto;
	text-align: center;
	.slide {
		display: none;
	}
	.slide.slick-slide:first-child {
		display: block;
	}
	.slide.slick-slide {
		display: block;
	}
	.slick-list {
		padding: 0;
		margin: 0 -25px;
		@include respond-to('small') {
			margin: 0 -20px;
		}
		@include respond-to('extra-small') {
			margin: 0 -15px;
		}
	}
	.slick-arrow {
		cursor: pointer;
	}
	&__img-small {
		// width: 196px;
		height: 150px;
		margin-top: 30px;
		margin-#{$left}: 25px;
		margin-#{$right}: 25px;
		position: relative;
		cursor: pointer;
		transition: all 0.4s;
		@include respond-to('large') {
			height: 140px;
		}
		@include respond-to('medium') {
			height: 100px;
		}
		@include respond-to('small') {
			margin-#{$left}: 20px;
			margin-#{$right}: 20px;
		}
		@include respond-to('extra-small') {
			margin-#{$left}: 15px;
			margin-#{$right}: 15px;
		}
		@include respond-to('mobile-l') {
			margin-#{$left}: 25px;
			margin-#{$right}: 25px;
		}
		@include respond-to('mobile-m') {
			margin-#{$left}: 20px;
			margin-#{$right}: 20px;
		}
		&_padding {
			width: 100%;
			height: 100%;
			overflow: hidden;
			position: relative;
			&:hover {
				& .gallery-img-small-shadow {
					background-color: rgba($color: #fff, $alpha: 0);
					&__title {
						opacity: 0;
						visibility: hidden;
					}
				}
			}
			& > img {
				max-width: 100%;
				width: 100%;
				min-height: 140px;
				height: 100%;
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				margin: 0 auto;
				@include respond-to('medium') {
					min-height: 100px;
				}
				@include respond-to('extra-small') {
					// 	min-height: 100px;
					// }
				}
			}
			&_youtube {
				&::before {
					content: "\f167";
					font-family: "Font Awesome 5 Brands";
					color: $clr-youtube;
					font-size: 32px;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					z-index: 2;
					cursor: pointer;
				}
			}
		}
	}
}

.slick-current {
	.prod-gallery-navi__img-small_padding {
		border: 2px solid $accent;
	}
	.gallery-img-small-shadow {
		background-color: rgba($color: #fff, $alpha: 0);
		&__title {
			opacity: 0;
			visibility: hidden;
		}
	}
}