$total-width: 850px;
$head-color: #f8f8f8;
$scrollbar-width: if-rtl(15px, 35px);

.side-form {
	$parent: &;

	width: $total-width;
	max-width: 80vw;
	height: 1050px;
	max-height: 100vh;
	position: fixed;
	top: 0;
	#{$left}: 0;
	z-index: 1000;
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.08);
	transform: if-rtl(translateX(-100%), translateX(100%));
	transition: 0.5s;
	&_opened {
		transform: translateX(0);
	} //_opened
	&__overlay {
		width: 100vw;
		height: 100vh;
		position: fixed;
		top: 0;
		#{$left}: 0;
		z-index: 999;
		background-color: transparent;
		display: none;
		&_revealed {
			display: block;
		} //_revealed
	} //__overlay
	&__btn-container {
		width: 100px;
		height: 220px;
		position: absolute;
		top: 33%;
		#{$right}: -99px;
		overflow: hidden;
		pointer-events: none;
	} //__btn-container
	&__btn {
		width: 160px;
		height: 160px;
		background-color: #fff;
		position: absolute;
		top: 50%;
		#{$right}: 30px;
		transform: translateY(-50%) rotate(-45deg);
		border-radius: 15px;
		box-shadow: 0 0 20px rgba(0, 0, 0, 0.08);
		cursor: pointer;
		pointer-events: all;
		@include respond-to('extra-small') {
			width: 70px;
			height: 70px;
			#{$right}: 65px;
		}
	} //__btn
	&__btn-content {
		transform: rotate(45deg);
		width: 100%;
		text-align: $left;
		padding-left: if-rtl(35px, 15px);
		padding-top: 15px; 
		margin-top: 0;
		transition: 0.5s;
		opacity: 1;
		span {
			display: block;
			width: 43%;
			margin-#{$left}: 70%;
			color: #008ea8;
			line-height: 1; 
			margin-right: -9%;
	    margin-top: -5%;
			@include respond-to('extra-small') {
				display: none;
			}
		} //span
		&_close {
			position: absolute;
			top: 50%;
			left: 50%;
			text-align: if-rtl(left, center);
			transform: translate(-50%, -50%) rotate(45deg);
			@include respond-to('extra-small') {
				#{$parent}__btn-image {
					width: 25%;
				}
			}
		} //_close
		&_hide {
			opacity: 0;
		}
	} //_btn-content
	&__btn-image {
    width: 32%;
    margin-left: 11px;
		margin-#{$left}: 70%;
		margin-bottom: 15px;
		@include respond-to('extra-small') {
			margin-top: 0;
			margin-#{$left}: 50%;
		}
	} //__btn-image
	&__head {
		position: relative;
		width: 100%;
		height: 100px;
		background-color: $head-color;
		border-bottom: 1px solid #c0c0c0;
		padding: 30px 50px;
		@include respond-to('small') {
			padding: side-values(25px 15px 5px 130px);
			& img {
				max-width: 100px;
			}
		}
		@include respond-to('extra-small') {
			padding: side-values(20px 15px 5px 130px);
			height: 85px;
		}
		@include respond-to('mobile-m') {
			padding: side-values(5px 15px 5px 170px);
			height: 85px;
		}
		span {
			float: $right;
			font-size: 30px;
			font-weight: 300;
			color: #008ea8;
			@include respond-to('small') {
				font-size: 26px;
			}
			@include respond-to('extra-small') {
				font-size: 17px;
			}
			&:last-of-type {
				font-weight: 900;
				padding-#{$right}: 10px;
				margin-#{$left}: 20px;
				@include respond-to('extra-small') {
					padding-#{$right}: 10px;
					margin-#{$left}: 0;
				}
				@include respond-to('mobile-l') {
					padding-#{$right}: 0;
					margin-#{$left}: 0;
				}
			} //last
		} //span
	} //__head
	&__logo {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		#{$left}: 30px;
		@include respond-to('small') {
			#{$left}: 15px; // float: #{$left};
			// //margin-right: 20px;
			// position: static;
			// top: auto;
			// transform: translate(0);
		}
		@include respond-to('extra-small') {
			// max-width: 80%;
			// margin: 30px auto 0;
			// position: relative;
			// display: block;
			// float: none;
		}
	}
	&__container {
		position: absolute;
		top: 100px;
		left: 0;
		width: calc(100% + #{$scrollbar-width});
		padding-#{$left}: $scrollbar-width;
		height: calc(100% - 100px);
		margin-#{$left}: $scrollbar-width * -1;
		overflow-x: hidden;
		overflow-y: scroll;
		background-image: if-rtl(url(../images/sidefor_bg.jpg), url(../images/sidefor_bg-r.jpg));
		background-position: left 31%;
		background-repeat: no-repeat;
		background-size: 100% auto;
		background-color: #fff;
		@include respond-to('extra-small') {
			height: calc(100% - 125px);
      top: 85px;
      bottom: 40px;
		}
	} //__container
	&__main {
		width: 100%;
		padding: 40px 50px;
		@include respond-to('extra-small') {
			padding: 20px 15px;
		}
	} //__main
	&__form {
		width: 100%;
		max-width: 350px;
		margin-bottom: 20px;
	} //__form
	&__form-title {
		margin-bottom: 15px;
		span {
			font-size: 18px;
			color: #777;
			font-weight: 300;
			line-height: 24px;
			display: block;
			&:last-of-type {
				font-size: 24px;
				color: #008ea8;
			} //last
		} //span
	} //__form-title
	.form-group {
		margin-bottom: 0;
	}
	.form-control {
		height: auto;
		background-image: none;
		border-radius: 0;
		box-shadow: none;
		transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
		font-size: 20px;
	}
	.form-input {
		width: 100%;
		margin-bottom: 5px;
		padding: 9px 0;
		border: none;
		border-bottom: 1px solid #c0c0c0;
		background: transparent;
		transition: color 0.4s ease, border 0.4s ease;
		color: #008ea8;
		font-size: 18px;
		&::placeholder {
			color: #777;
		}
		&:-webkit-autofill { 
			-webkit-box-shadow: 0 0 0 60px #fff inset;
		}
		&:hover,
		&:active,
		&:focus {
			border-bottom: 1px solid #008ea8;
			outline: none;
			&::placeholder {
				color: #008ea8;
			} 
		}
		&_select {
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
			// background-image: $arrow-select;
			background-position: $left center;
			background-repeat: no-repeat;
			& option:disabled {
				display: none;
			}
		}
		&_textarea {
			height: 115px;
			resize: none;
		}
	}
	.form-button {
		width: 100%;
		padding: 9px 0;
		border: none;
		transition: color 0.4s ease, border 0.4s ease;
		color: #fff;
		background-color: #008ea8;
		&:focus,
		&:active {

			@include btn-effect-active;
		}
	}
	.content-page {
		&__submit {
			color: #fff;
		}
	}
	//__contacts
	&__contact-group {
		margin-bottom: 30px;
		@include respond-to('small') {
			margin-bottom: 10px;
		}
	} //__contact-group
	&__contact-image {
		float: $right;
		width: 30px;
		text-align: $left;
		margin-#{$left}: 20px;
		margin-top: 5px;
		@include respond-to('small') {
			width: 20px;
			margin-#{$left}: 10px;
		}
		img {
			max-width: 100%;
		} //img
	} //contact-image
	&__contact-text {
		float: $right;
		span {
			display: block;
			font-size: 18px;
			line-height: 1;
			color: #777;
			font-weight: 300;
			@include respond-to('small') {
				background-color: rgba(255, 255, 255, 0.7);
				box-shadow: 0 0 12px #fff;
				font-size: 15px !important;
			}
			&:last-of-type {
				font-size: 24px;
				color: #008ea8;
			} //last
		} //span
	} //__contact-text
	&__soc {
		width: 300px;
		float: $left;
		text-align: left;
		@include respond-to('small') {
			float: $right;
			text-align: $right;
			width: auto;
		}
		& a {
			display: inline-block;
		}
	} //__soc
	&__soc-image {
		//float: $right;
		display: table-cell;
		vertical-align: middle;
		padding-#{$right}: 5px;
	} //img
	&__soc-item {
		display: table-row;
		vertical-align: middle;
		@include respond-to('small') {
			background-color: rgba(255, 255, 255, 0.7);
			box-shadow: 0 0 12px #fff;
		}
		span {
			display: table-cell;
			max-width: 80px;
			vertical-align: middle;
			color: #777;
			font-weight: 300;
			font-size: 16px;
			line-height: 1;
			text-align: right;
			padding-#{$right}: 20px;
			@include respond-to('extra-small') {
				padding-#{$right}: 10px;
			}
		} //span
		&::after {
			content: '';
			display: table-cell;
			clear: both;
		}
	} //__soc-item
	.content-page {
		&__input-text {

			@include respond-to('small') {
				height: 30px;
				@include placeholder {
					font-size: 14px;
				}
			}
		} //__input-text
		&__textarea {

			@include respond-to('small') {
				margin-bottom: 10px;
				@include placeholder {
					font-size: 14px;
				}
			}
		} //__textarea
		&__submit {
			line-height: 30px;
		}
	} //content-page
}

//side-form
