.wrapper-banners {
	position: relative;
	width: 100%;
	height: 100vh;
	overflow: hidden;
	@include respond-to('huge') {
		// height: 650px;
	}
	@include respond-to('large') {
		// height: 450px;
	}
	@include respond-to('extra-small') {
		// height: 300px;
	}
}

.wrap-banner-scroll {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	@include respond-to('medium') {
		position: static;
	}
}

.banner-item {
	width: 100%;
	min-height: 100vh;
	position: relative;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	@include respond-to('huge') {
		// min-height: 650px;
	}
	@include respond-to('large') {
		// min-height: 450px;
	}
	@include respond-to('extra-small') {
		// min-height: 300px;
	}
}

.black-block {
	position: absolute;
	top: 0;
	#{$right}: 0;
	height: 100%;
	width: 35.5%;
	background-color: rgba($color: #fff, $alpha: .85);
	@include respond-to('huge') {
		width: 40%;
	}
	@include respond-to('small') {
		width: 100%;
		bottom: 0;
		height: auto;
		top: auto;
		background-color: rgba($color: #fff, $alpha: .85);
	}
}

.banner-item .banner-text {
	position: absolute;
	top: 50%;
	#{$right}: 50%;
	transform: translateY(-50%);
	max-width: 270px;
	color: $accent;
	text-align: $right;
	// max-height: 400px;
	// overflow: hidden;
	@include respond-to('1550') {
		#{$right}: 30%;
	}
	@include respond-to('large') {
		padding: 0 15px;
		max-width: 330px;
		#{$right}: 10%;
	}
	@include respond-to('medium') {
		#{$right}: 2%;
	}
	@include respond-to('small') {
		position: static;
		top: auto;
		#{$right}: auto;
		transform: none;
		max-width: 100%;
		text-align: center;
		padding-bottom: 5px;
		padding-top: 10px;
	}
	&__title {
		max-width: 500px;

		@include respond-to('medium') {
			font-size: 25px;
		}
		@include respond-to('small') {
			margin: 0px auto 0;
			text-align: center;
			font-size: 20px;
			max-width: none;
		}
	}
	&__description {
		max-width: 430px;
		@include respond-to('small') {
			margin: 5px auto 0;
			text-align: center;
			font-size: 18px;
			max-width: none;
		}
		@include respond-to('extra-small') {
			font-size: 16px;
			margin: 0;
		}
	}
	&__link {
		@include respond-to('small') {
			margin: 5px auto 0;
			font-size: 18px;
			padding-top: 0px;
			border-top-width: 1px;
		}
	}
}
.banner-text__title.main-title {
	font-size: 60px;
	line-height: 1;
	@include respond-to('1100') {
		font-size: 50px;
	}
	@include respond-to('medium') {
		font-size: 45px;
	}
	@include respond-to('small') {
		font-size: 35px;
	}
}
.banner-text__title.main-title2 {
	font-size: 50px;
	line-height: 1;
	padding-bottom: 15px;
	font-weight: 700;
	@include respond-to('1100') {
		font-size: 40px;
	}
	@include respond-to('medium') {
		font-size: 35px;
	}
	@include respond-to('small') {
		font-size: 25px;
	}
}