.teams-desc {
	padding-bottom: 130px;
	@include respond-to('large') {
		padding-bottom: 100px;
	}
	@include respond-to('1100') {
		padding-bottom: 70px;
	}
	@include respond-to('medium') {
		padding-bottom: 50px;
	}
	@include respond-to('small') {
		padding-bottom: 35px;
	}
	& .content-text {
		& p {
			text-align: center;
		}
	}
}

.teams-block-wrap {}

.wrap-team-box {
	display: block;
	&__hr {
		margin: 40px 0;
		@include respond-to('small') {
			margin: 25px 0;
		}
	}
	&__right {
		float: $right;
		width: 27%;
		border-#{$left}: 5px solid #008ea8;
		@include respond-to('1100') {
			width: 25%;
		}
		@include respond-to('medium') {
			width: 100%;
			float: none;
			border-#{$left}: none;

		}
	}
	&__left {
		float: $left;
		width: 73%;
		@include respond-to('1100') {
			width: 75%;
		}
		@include respond-to('medium') {
			width: 100%;
			float: none;
		}
	}
	&_articles {
		padding-top: 40px;
		.wrap-team-box__right {
			width: 22%;
			@include respond-to('medium') {
				width: 100%;
				float: none;
			}
		}
		.wrap-team-box__left {
			width: 78%;
			@include respond-to('medium') {
				width: 100%;
				float: none;
			}
		}
		.team-box-image {
			height: 215px;
			max-width: 215px;
		}
		.team-box-data {
			max-width: 100%;
		}
	}
}

.team-box-image {
	position: relative;
	overflow: hidden;
	height: 262px;
	max-width: 262px;
	width: 100%;
	// margin: 0 auto;
	padding: 5px;
	border-radius: 50%;
	background: rgba(133, 151, 32, 1);
	background: linear-gradient(to right, rgba(133, 151, 32, 1) 0%, rgba(210, 230, 98, 1) 40%, rgba(210, 230, 98, 1) 60%, rgba(133, 151, 32, 1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#859720', endColorstr='#859720', GradientType=1);
	@include respond-to('1100') {
		height: 215px;
		max-width: 215px;
	}
	@include respond-to('medium') {
		margin: 0 auto 20px;
	}
	&__item {
		width: 100%;
		height: 100%;
		border-radius: 50%;
		background-position: center center;
		background-size: cover;
		// border: 1px solid #000;
	}
	&::after {
		content: "";
		position: absolute;
		#{$left}: -30px;
		top: 0;
		height: 100%;
		width: 5px;
		background-color: $accent;
		@include respond-to('small') {
			display: none;
		}
	}
}

.team-box-data {
	max-width: 750px;
	padding-#{$right}: 40px;
	@include respond-to('medium') {
		max-width: 100%;
		padding-#{$right}: 0px;
	}
	&__title {
		font-size: 35px;
		font-weight: 900;
		color: $accent;
		line-height: 1;
		@include respond-to('small') {
			font-size: 30px;
		}
	}
	&__subtitle {
		margin-bottom: 30px;
		font-size: 30px;
		font-weight: 400;
		color: $accent;
		line-height: 1;
		@include respond-to('small') {
			margin-bottom: 15px;
			font-size: 25px;
		}
	}
	&__content {
		overflow: hidden;
		& > p {
			font-size: 18px;
			color: $accent;
			margin: 0;
			line-height: 1.2;
			font-weight: 400;
			@include respond-to('small') {
				font-size: 17px;
			}
			@include respond-to('extra-small') {
				font-size: 16px;
			}
		}
	}
	&__btn {
		display: inline-block;
		margin-top: 30px;
		padding-bottom: 8px;
		border-bottom: 1px solid #008ea8;
		font-size: 18px;
		font-weight: 900;
		@include link_no-hover($accent);
	}
}