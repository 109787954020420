.blog-block-wrap {
	padding-top: 20px;
}
.wrap-article-box {
	display: block;
	&:hover {
		.article-box-image__item {
			transform: scale(1.07);
		}
	}
}

.article-box-image {
	width: 100%;
	height: 340px;
	overflow: hidden;
	&__item {
		background-position: center;
		background-size: cover;
		width: 100%;
		height: 100%;
		transition: all 0.6s;
	}
}

.article-box-data {
	&__title {
		font-size: 30px;
		font-weight: 700;
		color: $accent;
		line-height: 1;
		@include respond-to('extra-small') {
			font-size: 22px;
		}
	}
	&__subtitle {
		font-size: 25px;
		font-weight: 400;
		color: #000;
		line-height: 1;
	}
	&__data {
		font-size: 18px;
		font-weight: 400;
		color: #000;
		line-height: 1;
	}
	&__subtitle2 {
		margin-top: 10px;
		font-size: 20px;
		font-weight: 500;
		color: $accent;
		line-height: 1;
	}
	&__content {
		overflow: hidden;
		padding-top: 30px;
		text-align: justify;
		& > p {
			font-size: 18px;
			font-weight: 300;
			color: #000;
			margin: 0;
			line-height: 1.2;
		}
	}
}

.article-box-btn {
	display: inline-block;
	max-width: 200px;
	width: 100%;
	margin-top: 25px;
	padding: 15px 20px;
	background-color: $accent;
	font-size: 18px;
	font-weight: 600;
	text-align: center;
	box-shadow: 2px 2px 20px 0px rgba($color: #000000, $alpha: 0.3);
	@include link_no-hover(#fff);
	&:active {
		@include btn-effect-active;
	}
}

.hr-article {
	margin: 40px 0;
	border-top: 1px solid #e6e6e6;
}