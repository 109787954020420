.wrap-content {
	direction:  if-rtl('rtl', 'ltr');
	
	padding: 50px 0;
	@include respond-to('medium') {
		padding: 40px 0;
	}
	@include respond-to('small') {
		padding: 35px 0;
	}
	@include respond-to('extra-small') {
		padding: 25px 0;
	}
}

.wrap-all-content-not-banner {
	margin-top: 180px;
	@include respond-to('medium') {
		margin-top: 80px;
	}
}

.content-text {

	h2,
	h3 {
		font-size: 30px;
		font-weight: 700;
		line-height: 1.2;
		color: $accent;
		margin: 20px 0 20px;
		@include respond-to('large') {
			font-size: 26px;
		}
		@include respond-to('extra-small') {
			font-size: 20px;
		}
	}
	p {
		font-size: 18px;
		font-weight: 400;
		color: $accent;
		line-height: 1.4;
		@include respond-to('extra-small') {
			font-size: 16px;
			text-align: justify;
		}
	}
	iframe {
		@include respond-to('small') {
			text-align: center !important;
			margin: 15px auto !important;
			width: 100% !important;
			height: auto !important;
			float: none !important;
			display: block !important;
		}
	}
	img {
		@include respond-to('small') {
			float: none !important;
			text-align: center !important;
			width: auto !important;
			height: auto !important;
			margin: 15px auto !important;
		}
	}
}

.h1-content,
.h2-content {
	margin: 0;
}
.h1-content {
	font-size: 40px;
}
.h2-content {
	font-size: 40px;
}
.center {
	text-align: center;
}
.w100 {
	width: 100%;
}
.pdb70 {
	padding-bottom: 70px;
}


.ul-list ul {
	list-style: none;
	padding: 0;

}

.ul-list ul li {
	position: relative;
	padding-right: 25px;
	padding-left: 25px;   
	color: $accent;
	font-size: 18px;
	font-weight: 400;
}
.ul-list ul li:after {
	content: url(../images/listimg.png);
	position: absolute;
	#{$right}: 0;
	top: -1px;
}
